import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Select, Modal, message, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import 'react-simple-keyboard/build/css/index.css';
import { useMutation } from '@apollo/client';
import htmlToDraft from 'html-to-draftjs';
import * as axios from 'axios';
import _ from 'lodash';
import styled from 'styled-components';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { EditorState, ContentState } from 'draft-js';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import ReactPlayer from 'react-player';
import * as diff_match_patch from 'diff-match-patch';
import { LoadingOutlined } from '@ant-design/icons';
import { captureUserMedia } from 'src/utils';
import { LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import queryString from 'query-string';

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageButton = styled.img`
  max-height: 50px;
  cursor: pointer;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  width: 100%;

  height: 100%;
  background-color: #dfe7f5;
`;
const StageTitle = styled.div`
  /* border-bottom: 1px solid #4472c4; */
  width: 100%;
  color: #27666c;
  font-size: 32px;
  font-weight: bold;
  padding: 15px 30px;
  margin-bottom: 15px;
  background-color: #f2f2f2;
  @media (max-width: 1280px) {
    font-size: 25px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #2f5597;
  padding: 5px 10px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 105px);
  padding: 0 45px 15px 45px;
  align-items: center;
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 13px;
  padding: 0 0px 25px;
  background-color: #ffffff;
  max-width: 1650px;
  min-width: 1350px;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 900px;
  }
`;

const Button = styled.div`
  cursor: pointer;
  border-radius: ${(props) => (props.radius ? '20px' : '10px')};
  font-size: 1.1em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  padding: ${(props) => (props.radius ? '9px 30px' : '9px 18px')};
  border: ${(props) => (props.radius ? 'none' : '2px #dae3f3 solid')};
  @media (max-width: 1720px) {
    padding: ${(props) => (props.radius ? '9px 20px' : '8px 15px')};
    font-size: 1em;
    /* padding: 8px 15px; */
  }
  @media (max-width: 1280px) {
    padding: ${(props) => (props.radius ? '9px 17px' : '8px 15px')};
    font-size: 0.8em;
    font-weight: normal;
    padding: 8px 14px;
  }
`;

const PageButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.3em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  padding: 6px 15px;
  margin: 0 5px;
  max-width: 50px;
  min-width: 50px;
  text-align: center;
`;

const BlankImageModal = styled(Modal)`
  .ant-modal-header {
    background-color: #f5faff;
    border-radius: 20px;
  }
  .ant-modal-content {
    border-radius: 20px;
    background-color: #f5faff;
  }

  .ant-modal-body {
    border-radius: 20px;
    background-color: #f5faff;
  }
`;

const dmp = new diff_match_patch();

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

const WPM_GOAL = {
  1: 150,
  2: 150,
  3: 150,
  4: 150,
  5: 150,
  6: 150,
  7: 160,
  8: 160,
};

const LevelupRecording = ({ dataNew, articleData, userData, code, idx, start_time, end_time, externalUserStudyData }) => {
  const history = useHistory();
  const { external_study_idx } = queryString.parse(window.location.search);
  const [showModal, setShowModal] = useState(false);
  const [showStartModal, setShowStartModal] = useState(true);
  const [text, setText] = useState(EditorState.createEmpty());
  const [textRest, setTextResp] = useState(EditorState.createEmpty());
  const [currentLessonText, setCurrentLessonText] = useState([]);
  const [firstPageNo, setFirstPageNo] = useState(0);
  const [doneLessonText, setDoneLessonText] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [currentText, setCurrentText] = useState([]);
  const [lastText, setLastText] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState([]);
  const [startPageIndx, setStartPageIndx] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSize, setImageSize] = useState('100%');
  const [currentRecLength, setCurrentRecLenght] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [recordingData, setRecordignData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [lastBlob, setLastBlob] = useState(null);
  const recordAudio = useRef(null);
  const recorderStreams = useRef(null);
  const [blobChunks, setBlobChunks] = useState([]);
  const currentSttRequest = useRef([]);
  const currentSttText = useRef([]);
  const [currentProgressSlider, setCurrentProgressSlider] = useState(0);
  const [currentDoneValue, setCurrentDoneValue] = useState(null);
  const [processCallbackLoading, setProcessCallbackLoading] = useState([]);
  const currentTextValue = currentText.map((e) => e.text).join('\n');
  const [apiSuccess, setApiSuccess] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const exportToS3 = (filename, url, cb) => {
    setLoading(true);
    return new Promise(async (resolve, reject) => {
      try {
        const fileData = await axios.post('https://llf63tb67l3wj7nqerumdipm440stovy.lambda-url.ap-northeast-2.on.aws', {
          url: url,
          fileName: '-' + filename + '.pdf',
        });
        const fileUrl = fileData.data;
        // const fullUploader = await upload({ variables: { url: url, filename: '-' + filename + '.pdf' } });

        await cb(fileUrl);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    });
  };

  const [levelUpUpdateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(
    LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT,
    {
      onError(error) {
        console.log('error', error);
      },
    },
  );

  useEffect(() => {
    if (dataNew.length > 0 && !apiSuccess) {
      setApiSuccess(true);
      let recData = dataNew.map((item, indx) => ({ ...item, page_no: indx, page: indx }));
      const recIndx = recData.reduce(function (acc, d, i) {
        acc.push(i);
        return acc;
      }, []);
      if (recIndx[recIndx.length - 1] === recData.length) {
        recData = recData.slice(recIndx[0]);
      } else {
        recData = recData.slice(recIndx[0], recIndx[recIndx.length - 1] + 1);
      }
      setTotalPage(recData.length);
      setFirstPageNo(recData[0]['page']);
      let donePages = window.localStorage.getItem(`PASSAGE_${code}_${userData.idx}`);
      donePages = donePages ? JSON.parse(donePages) : [];
      let startPageIndx = recData[0]['page'];
      let soneKeys = Object.keys(donePages);

      if (soneKeys.length) {
        startPageIndx = parseInt(soneKeys.pop());
        let tempData = [];
        donePages.forEach((e, i) => {
          if (e) tempData[i] = e;
        });
        currentSttText.current = tempData;
        message.info(` ${startPageIndx + 1} 페이지까지 학습 기록이 있습니다. 다음 페이지부터 이어서 학습하세요. `);
      }

      setStartPageIndx(startPageIndx);
      setRecordignData(recData);

      //   // if(![0,1].includes(data?.getRecordingStudyData[0]["page_no"])) message.info(`Goto page ${data?.getRecordingStudyData[0]["page_no"]}`);
    }
  }, [dataNew]);

  useEffect(() => {
    if (recordingData.length) {
      // let phraseList = recordingData.filter((e) => parseInt(e.recording));
      let phraseList = recordingData.map((e) => e.text);
      setCurrentLessonText(phraseList);
    }
  }, [recordingData, processCallbackLoading]);

  useEffect(() => {
    let donePages = window.localStorage.getItem(`PASSAGE_${code}_${userData.idx}`);
    donePages = donePages ? JSON.parse(donePages) : [];

    setCurrentDoneValue(donePages[currentPage]);
  }, [currentPage, processCallbackLoading]);

  const sleep = (m) => new Promise((r) => setTimeout(r, m));

  useEffect(() => {
    if (isRecording) {
      setCurrentRecLenght(0);
      startRecord(currentPage);
    } else {
      if (recordAudio.current && recordAudio.current[currentPage]) {
        stopRecording(currentPage);
      } else {
        stopRecording(lastPage);
      }
    }
  }, [isRecording]);

  useEffect(() => {
    setIsRecording(false);
    if (apiSuccess) {
      setLastText(currentText);
      let phraseList = recordingData.filter((e) => currentPage == e.page);
      if (phraseList.length) {
        // let recordginPage = phraseList.filter((e) => parseInt(e.recording));
        setCurrentText(phraseList);
      } else if (gotoNext && Object.values(currentSttText.current).length > 0 && recordingData.length) {
        const fitlerrec = recordingData.filter((p) => p.text);
        const allReqDone = Object.values(currentSttRequest.current).filter((p) => !p);
        if (!allReqDone.length) {
          let soneKeys = Object.keys(currentSttText.current).map((p) => parseInt(p));
          let filNotDone = fitlerrec.filter((r) => !soneKeys.includes(r.page_no));
          if (filNotDone.length) {
            Modal.destroyAll();
            Modal.error({
              icon: null,
              title: 'Not Complete',
              content: (
                <Title level={4}>
                  {`  학습이 완료되지 않았습니다.`}
                  <br />
                  {`${filNotDone.map((p) => p.page_no).join(',')} 페이지를 확인하세요.`}
                </Title>
              ),
            });
          } else {
            window.localStorage.removeItem(`PASSAGE_${code}_${userData.idx}`);
            const cSttText = Object.values(currentSttText.current);
            const accuracy = parseInt(cSttText.reduce((p, t) => p + t.accuracy, 0) / cSttText.length);
            const wpm = parseInt(cSttText.reduce((p, t) => p + t.wpm, 0) / cSttText.length);
            Modal.destroyAll();
            Modal.info({
              icon: null,
              title: 'Done',
              okText: 'Submit',
              content: <Title level={4}>{`  Your score is ${accuracy}.`}</Title>,
              onOk: () => {
                gotoNext(
                  {
                    exam_total: 100,
                    exam_correct: accuracy,
                    message: `Your score is ${accuracy}.`,
                    exitMessage: `학습을 종료하시겠습니까?`,
                    recording_data: cSttText.map((e) => `<p>${e.text}</p>`).join(''),
                    wpm,
                  },
                  true,
                );
              },
            });
          }
        } else if (!showLoading) {
          setShowLoading(true);
        }
      } else {
        setCurrentText([]);
      }
    }
  }, [currentPage, startPageIndx, showLoading, apiSuccess]);

  const openModel = () => {
    let filteredTexts = [currentTextValue].filter((f) => f !== '' && f);
    console.log(currentTextValue);
    const { contentBlocks, entityMap } = htmlToDraft(filteredTexts.map((e) => `<p>${e}</p>`).join());
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    setText(EditorState.createWithContent(contentState));

    const cb2 = htmlToDraft(
      Object.values(currentDoneValue ? [currentDoneValue] : [])
        .map((e) => `<p>${e.text}</p>`)
        .join(),
    );
    const contentState2 = ContentState.createFromBlockArray(cb2.contentBlocks, cb2.entityMap);
    setTextResp(EditorState.createWithContent(contentState2));

    setShowModal(true);
  };

  const processRecordCallback = useCallback(
    async (blob, textA, currentRecLength, page) => {
      let fd = new FormData();
      let text = textA.map((p) => p);
      text.push('[unk]');
      fd.append('audio', blob);
      fd.append('sample_rate', 16000);
      currentSttRequest.current[page] = false;

      let sanitizePhraseList = text.map((e) =>
        e
          .replaceAll('\n', ' ')
          .replace(/[.,?!’“”<>()]/gi, '')
          .toLowerCase(),
      );
      fd.append('phrase_list', JSON.stringify(sanitizePhraseList));

      processCallbackLoading[page] = true;
      setProcessCallbackLoading(JSON.parse(JSON.stringify(processCallbackLoading)));

      const res = await axios('https://kaldi-stt-api.cloubot.com/process', {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${new Date().getTime()}` },
        method: 'POST',
        data: fd,
      });
      processCallbackLoading[page] = false;
      setProcessCallbackLoading(JSON.parse(JSON.stringify(processCallbackLoading)));

      let prev = currentSttText.current;

      let phList = sanitizePhraseList.reduce((p, n) => p.concat(n.split(' ')), []).filter((p) => p !== '' && p !== '[unk]');
      let resData = res.data;
      let dataSplit = resData.text.split(' ');
      resData.text = resData.text.replace('[unk]', '');

      // const filteredArray = phList.filter(value => dataSplit.includes(value));
      dmp.Diff_Timeout = 5;
      dmp.Diff_EditCost = 5;
      resData['accuracy'] = 0;
      resData['duration'] = currentRecLength;
      resData['wpm'] = (dataSplit.length / (currentRecLength / 1000)) * 60;
      const d = dmp.diff_main(
        textA
          .map((e) =>
            e
              .replaceAll('\n', ' ')
              .replace(/[.,?!’“”<>()]/gi, '')
              .replace(',', ' ')
              .toLowerCase(),
          )
          .reduce((p, n) => p + n, ''),
        resData.text,
      );
      dmp.diff_cleanupSemantic(d);
      let htmltext = [];
      d.forEach((element, i) => {
        if (element[0] === 0) {
          const filteredArrayl = element[1].split(' ').filter((value) => phList.includes(value));
          resData['accuracy'] += filteredArrayl.length;
          htmltext.push(`<span style="color:black;">${element[1]}</span>`);
        } else if (element[0] === -1) {
          const filteredArrayl = element[1].split(' ').filter((value) => phList.includes(value));
          resData['accuracy'] = resData['accuracy'] ? resData['accuracy'] - filteredArrayl.length / 6 : 0;
          htmltext.push(`<span style="color:brown; text-decoration:line-through;">${element[1]}</span>`);
        } else if (element[0] === 1) {
          htmltext.push(`<span style="color:darkblue;">${element[1]}</span>`);
        }
      });
      console.log(resData['accuracy'], phList.length, resData['accuracy'] / (phList.length / 100));
      let tempAccuracy = resData['accuracy'] / (phList.length / 100);
      tempAccuracy = tempAccuracy > 100 ? 100 : tempAccuracy;
      resData['accuracy'] = tempAccuracy > 0 ? Math.floor(tempAccuracy) : 0;
      resData.text = htmltext.join('');
      prev[page] = resData;

      currentSttRequest.current[page] = true;
      window.localStorage.setItem(`PASSAGE_${code}_${userData.idx}`, JSON.stringify(prev));
      setCurrentDoneValue(resData);
      setShowLoading(false);

      currentSttText.current = prev;
    },
    [currentSttRequest],
  );

  const stopRecording = (page) => {
    if (recordAudio.current != null && recordAudio.current[page]) {
      // message.info('Recording Stopped');
      recordAudio.current[page].stopRecording(async () => {
        setIsLoading(true);
        setDoneLessonText((prev) => {
          let temp = prev.concat(currentLessonText);
          return temp;
        });
        processRecordCallback(
          recordAudio.current[page].getBlob(),
          currentText.map((e) => e.text),
          currentRecLength,
          page,
        );
        setIsLoading(false);
        setLastBlob(recordAudio.current[page].toURL());
        recordAudio.current[page].destroy();
        recordAudio.current[page] = null;
        if (recorderStreams.current) {
          recorderStreams.current.getTracks().forEach((track) => track.stop());
          recorderStreams.current = null;
        }
      });
    }
  };

  const pauseRecording = async (page) => {
    if (recordAudio.current && recordAudio.current[page]) {
      if (recordAudio.current[page].state === 'recording') {
        recordAudio.current[page].pauseRecording();
        try {
          let lastBlob = new Blob(recordAudio.current[page].buffer);
          setLastBlob(URL.createObjectURL(lastBlob));
        } catch (e) {
          console.log(e);
        }
      } else {
        recordAudio.current[page].resumeRecording();
        setPlaying(false);
      }
    }
  };

  const onDataAvailableCallback = useCallback(async (blob) => {
    setImageSize(100 - Math.floor(Math.random() * 10 + 1) + '%');

    setCurrentRecLenght((prev) => prev + 100);
    blob = await new Response(blob).arrayBuffer();

    setBlobChunks((prev) => {
      prev.push(blob);
      return prev;
    });
  }, []);

  const startRecord = (page) => {
    if (!isActive) return;
    captureUserMedia({ audio: { echoCancellation: true } }, async (stream) => {
      if (!recordAudio.current) recordAudio.current = [];
      recorderStreams.current = stream;
      recordAudio.current[page] = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm',
        sampleRate: 48000,
        desiredSampRate: 16000,

        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,

        //1)
        // get intervals based blobs
        // value in milliseconds
        // as you might not want to make detect calls every seconds
        timeSlice: 100,

        // 2)
        // as soon as the stream is available
        ondataavailable: onDataAvailableCallback,
      });
      setBlobChunks([]);
      setLastBlob(null);
      recordAudio.current[page].startRecording();

      await sleep(500);
      message.info('Recording Started');
    });
  };

  const pageMove = (page) => {
    setCurrentPage(page);
  };

  const handleCloseClick = () => {
    Modal.confirm({
      icon: null,
      title: 'Exit',
      content: (
        <Title level={4} style={{ textAlign: 'center', lineHeight: 2 }}>
          학습이 완료되지 않았습니다. 재접속시 기존 학습 이후부터 진행됩니다. 학습을 종료하시겠습니까?
        </Title>
      ),
      onOk: () => history.push('/'),
    });
  };
  const gotoNext = async (data) => {
    const obj = {
      idx: parseInt(external_study_idx),
      recording_data: data.recording_data,
      wpm: data.wpm,
      score: data.exam_correct,
      status: 2,
      start_time: start_time,
      end_time: end_time,
    };

    await levelUpUpdateAITopicUserAssignment({
      variables: {
        idx: parseInt(external_study_idx),
        status: 2,
        recording_data: JSON.stringify(obj),
      },
    });

    // if (!externalUserStudyData?.assigned_idx) {
      await exportToS3(
        `-level-up-report-result-${externalUserStudyData.idx}`,
        `https://culp.cloubot.com/level-up/report/generate/${externalUserStudyData.idx}?pdfType=student`,
        async (fileUrl) => {
          let externalData = externalUserStudyData?.data ? JSON.parse(externalUserStudyData?.data) : {};
          await levelUpUpdateAITopicUserAssignment({
            variables: {
              idx: parseInt(external_study_idx),
              status: 2,
              rubric_data: JSON.stringify(
                externalData?.rubric_data ? { ...JSON.parse(externalData?.rubric_data || '{}'), pdf_url: fileUrl } : { pdf_url: fileUrl },
              ),
            },
          });
          history.goBack();
        },
      );
    // } else {
    //   history.goBack();
    // }
  };

  const handleUploadClick = () => {
    const fitlerrec = recordingData.filter((p) => p.text);
    const allReqDone = Object.values(currentSttRequest.current).filter((p) => !p);
    if (!allReqDone.length) {
      let soneKeys = Object.keys(currentSttText.current).map((p) => parseInt(p));
      let filNotDone = fitlerrec.filter((r) => !soneKeys.includes(r.page_no));
      if (filNotDone.length) {
        Modal.destroyAll();
        Modal.error({
          icon: null,
          title: 'Not Complete',
          content: (
            <Title level={4}>
              {`  학습이 완료되지 않았습니다.`}
              <br />
              {`${filNotDone.map((p) => p.page_no + 1).join(',')} 페이지를 확인하세요.`}
            </Title>
          ),
        });
      } else {
        window.localStorage.removeItem(`PASSAGE_${code}_${userData.idx}`);
        const cSttText = Object.values(currentSttText.current);
        const accuracy = parseInt(cSttText.reduce((p, t) => p + t.accuracy, 0) / cSttText.length);
        const wpm = parseInt(cSttText.reduce((p, t) => p + t.wpm, 0) / cSttText.length);
        Modal.destroyAll();
        Modal.info({
          icon: null,
          title: 'Done',
          okText: 'Submit',
          content: <Title level={4}>{`  Your score is ${accuracy}.`}</Title>,
          onOk: () => {
            gotoNext(
              {
                exam_total: 100,
                exam_correct: accuracy,
                message: `Your score is ${accuracy}.`,
                exitMessage: `학습을 종료하시겠습니까?`,
                recording_data: cSttText.map((e) => `<p>${e.text}</p>`).join(''),
                wpm,
              },
              true,
            );
            setProcessCallbackLoading([]);
            currentSttText.current = [];
          },
        });
      }
    }
  };

  const totalWpm = Object.values(currentDoneValue ? [currentDoneValue] : []).length
    ? parseInt(
        Object.values(currentDoneValue ? [currentDoneValue] : []).reduce((p, t) => p + t.wpm, 0) /
          Object.values(currentDoneValue ? [currentDoneValue] : []).length,
      )
    : 0;

  const totalAccuracy = currentDoneValue ? parseInt(currentDoneValue.accuracy) : 0;

  const totalLessonText = Object.values(currentDoneValue ? [currentDoneValue] : [])
    .map((e) => `<p>${e.text}</p>`)
    .join();

  const word_count = dataNew.length > 0 ? dataNew[currentPage]['text'].split(' ').length : 0;

  if (dataNew.length == 0) {
    return '';
  }
  return (
    <>
      <Wrapper className="mainExternal-dashboard-con">
        <Modal
          title={'Match recording'}
          visible={showModal}
          width="100%"
          bodyStyle={{ height: 'calc(100vh - 200px)', overflowY: 'auto', padding: 10 }}
          style={{ top: 20, height: '80%' }}
          closable={true}
          footer={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Title level={4} style={{ color: 'darkblue', marginTop: '10px', marginLeft: '20px' }}>
                  Extra Text
                </Title>
                <Title level={4} style={{ color: 'brown', marginTop: '10px', marginLeft: '20px' }}>
                  Missed Text
                </Title>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button type="primary" key="back" style={{ margin: 10 }} onClick={() => setShowModal(false)}>
                  Done
                </Button>
                <Title level={3} style={{ margin: 10 }}>
                  WPM: {totalWpm}
                </Title>
                <Title level={3} style={{ margin: 10 }}>
                  Accuracy: {totalAccuracy}%
                </Title>
              </div>
            </div>
          }
          onCancel={() => setShowModal(false)}
        >
          <div style={{ display: 'flex', height: '100%', fontWeight: 600, color: '#383838' }}>
            <div style={{ flex: 1, background: 'beige', marginRight: 10 }}>
              <Title level={3} style={{ textAlign: 'center' }}>
                Content
              </Title>
              <Editor
                editorState={text}
                editorStyle={{
                  width: '100%',
                  height: 'calc(100% - 20px)',
                  backgroundColor: 'beige',
                  padding: 10,
                  fontSize: '18px',
                }}
                toolbarClassName="hide-print"
                wrapperClassName="essay-wrapper"
                editorClassName="essay-editor"
                readOnly={true}
                toolbarHidden
              />
            </div>
            <div style={{ flex: 1, backgroundColor: '#93babb', fontWeight: 600, color: '#383838' }}>
              <Title level={3} style={{ textAlign: 'center' }}>
                Your Response
              </Title>
              <Editor
                editorState={textRest}
                rootStyle={{ backgroundColor: '#93babb' }}
                editorStyle={{
                  width: '100%',
                  height: 'calc(100% - 20px)',
                  backgroundColor: '#93babb',
                  padding: 10,
                  fontSize: '18px',
                  lineHeight: '25px',
                }}
                style={{ backgroundColor: '#93babb' }}
                toolbarClassName="hide-print"
                wrapperClassName="essay-wrapper"
                editorClassName="essay-editor"
                readOnly={true}
                toolbarHidden
              />
            </div>
          </div>
        </Modal>

        <MainWrapper>
          <Main>
            <StageTitle>Presentation</StageTitle>
            <div
              className="external-content-wrap fluency-con"
              style={{
                height: 'calc(100vh - 200px)',
              }}
            >
              <div className="lesson1-con fluency-bg">
                <div className="lesson1_wrap fluency-wrap">
                  <div className="fluency-left">
                    <div className="sentence-wrap" style={{ background: '#fff', overflowY: 'scroll', overflowX: 'auto' }}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: dataNew.length > 0 ? dataNew[currentPage]['text'].replace(/\n/g, '<br/>') : '',
                        }}
                        style={{ lineHeight: '30px' }}
                      ></p>
                    </div>
                    <div className="fluency-wrap control-box">
                      <PageButton
                        color={'#ffffff'}
                        back={'#ffbd00'}
                        onClick={() => !isRecording && pageMove(currentPage ? currentPage - 1 : 0)}
                      >
                        <ArrowLeftOutlined />
                      </PageButton>
                      <div className="pager">
                        <span>
                          {currentPage + 1}/{dataNew.length}
                        </span>
                      </div>
                      <PageButton
                        color={'#ffffff'}
                        back={'#ffbd00'}
                        onClick={() => !isRecording && pageMove(currentPage < totalPage - 1 ? currentPage + 1 : currentPage)}
                      >
                        <ArrowRightOutlined />
                      </PageButton>
                    </div>
                  </div>
                  <div className="fluency-right">
                    <div className="right-con right">
                      <div className="top-header">
                        <h2>WPM / Accuracy</h2>
                      </div>
                      {loading || loadingUpdate ? (
                        <div
                          style={{
                            backgroundColor: '#ffbd00',
                            borderRadius: '10px',
                            marginTop: '20px',
                            height: '45vh',
                          }}
                        >
                          <Spin
                            size="large"
                            style={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '100px',
                            }}
                          />
                        </div>
                      ) : (
                        <div className="btm-con">
                          <div className="count-con">
                            <div>
                              <h3 style={{ fontWeight: '700' }}>Word Count</h3>
                              <h3 className="count-num">{word_count}</h3>
                            </div>

                            {/* <div>
                            <h3 style={{ fontWeight: '700' }}>WPM Goal</h3>
                            <h3 className="goal-num">
                              <span>{WPM_GOAL[1]}</span>
                            </h3>
                          </div> */}

                            <div>
                              <h3 style={{ fontWeight: '700' }}>WPM</h3>
                              <h3 className={`goal-num ${(currentDoneValue?.wpm || 0) > WPM_GOAL[1] ? 'green-number' : 'red-number'}`}>
                                <span>{currentDoneValue ? parseInt(currentDoneValue.wpm) : 0}</span>
                              </h3>
                            </div>
                            <div>
                              <h3 style={{ fontWeight: '700' }}>Accuracy</h3>
                              <h3 className={`goal-num ${(currentDoneValue?.accuracy || 0) > 80 ? 'green-number' : 'red-number'}`}>
                                <span>{currentDoneValue ? currentDoneValue.accuracy : 0}</span>
                              </h3>
                            </div>
                          </div>

                          <div className="result-con" style={{ background: '#fff', flexDirection: 'column' }}>
                            <div className="count-bar">
                              <input type="range" className="soundSlider" min={0} max={100} step={1} value={currentProgressSlider} />
                            </div>

                            <div className="btn-wrap">
                              <img
                                className={`${isRecording ? 'blink_dark' : ''}`}
                                src="/images/book/report/stop-icon.png"
                                onClick={() => {
                                  if (isRecording) message.info('저장되었습니다.');
                                  setIsRecording(!isRecording);
                                }}
                                alt="start Btn"
                              />
                              <img
                                src={playing ? '/images/book/report/stop-play.png' : '/images/book/report/play-icon.png'}
                                onClick={() => {
                                  if (lastBlob) {
                                    setPlaying((prev) => !prev);
                                  }
                                }}
                                alt="play Btn"
                              />
                              <img src="/images/book/report/upload-icon.png" onClick={handleUploadClick} alt="share Btn" />
                            </div>

                            <div className="right-con result-time-wrap">
                              <h3 onClick={() => !processCallbackLoading[currentPage] && openModel()}>Result</h3>
                              {processCallbackLoading[currentPage] ? (
                                <Spin size="large" indicator={antIcon} style={{ position: 'absolute', maxWidth: '100px' }} />
                              ) : null}
                              <h3>
                                After <span>{parseInt((word_count * 3) / 10)}</span> seconds
                              </h3>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Main>
        </MainWrapper>

        {playing ? (
          <ReactPlayer
            controls
            width={0}
            height={0}
            url={lastBlob}
            loop={false}
            playing={playing}
            onEnded={() => setPlaying(false)}
            onError={() => setPlaying(false)}
            onProgress={(progress) => {
              console.log((progress.playedSeconds / progress.loadedSeconds) * 100);

              setCurrentProgressSlider((progress.playedSeconds / progress.loadedSeconds) * 100);
            }}
          />
        ) : null}

        <BlankImageModal
          open={showStartModal}
          footer={null}
          closeIcon={
            <img
              src="/images/ai_coach/icon/exit_4.png"
              alt="exit"
              style={{
                marginTop: '-50px',
                marginRight: '-50px',
              }}
            />
          }
          onCancel={() => setShowStartModal(false)}
        >
          <ColumnFlex style={{ justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
            <Title style={{ marginBottom: '10px', fontWeight: '900', textAlign: 'center' }}>
              Presentation <br /> (WPM)
            </Title>
            <ImageButton
              src="/images/ai_coach/icon/start_2.png"
              style={{ marginTop: '10px' }}
              onClick={() => {
                setShowStartModal(false);
              }}
            />
          </ColumnFlex>
        </BlankImageModal>
      </Wrapper>
    </>
  );
};

export default LevelupRecording;
