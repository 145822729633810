import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Col, Typography, Row, Button, List, Form, Checkbox, DatePicker, Select, Input, Switch, Modal } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import moment from 'moment';
import queryString from 'query-string';
import { isAfterSchool } from 'src/utils';
import * as _ from 'lodash';
import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';
import FormField from 'src/components/common/FormField';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { customDayOfWeek } from 'src/utils';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const yoilOptions = [
  { label: '일', value: '일' },
  { label: '월', value: '월' },
  { label: '화', value: '화' },
  { label: '수', value: '수' },
  { label: '목', value: '목' },
  { label: '금', value: '금' },
  { label: '토', value: '토' },
];
const levelList = [
  { value: 'Grade 1', label: 'Basic A' },
  { value: 'Grade 4', label: 'Basic B' },
  {
    value: 'Grade 7',
    label: 'Intermediate',
  },
  {
    value: 'Grade 11',
    label: 'Advanced',
  },
  { value: 'College Level', label: 'College' },
];
const TestBlock = styled.div`
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  max-height: calc(95vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

const AssignLevelUp = () => {
  const companyName = useSelector(classStoreData);
  const history = useHistory();
  // const { idx } = useParams();
  const [formControl] = Form.useForm();
  const [firstForm] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [thirdForm] = Form.useForm();
  const [fourthForm] = Form.useForm();
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [selectYoil, setSelectYoil] = useState([]);
  const [selectStudents, setSelectStudents] = useState([]);
  const [testTime, setTestTime] = useState('00:00');
  const [events, setEvents] = useState([]);
  const [listData, setListData] = useState([]);
  const [rangeDate, setRangeDate] = useState({ start: null, end: null });
  const [useBookReport, setUseBookReport] = useState(false);
  const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));
  const { classIdx, userIdx } = queryString.parse(window.location.search.replace('?', ''));
  const [stage, setStage] = useState(0);

  const [dueDate, setDueDate] = useState(null);
  const [level, setLevel] = useState('Grade 1');
  const [sentences, setSentences] = useState(60);
  const [version, setVersion] = useState('short');
  const [feedback, setFeedback] = useState(false);
  const [revise, setRevise] = useState(false);

  const [options, setOptions] = useState([]);
  const [optionidx, setOptionidx] = useState(null);
  const [defaultOption, setDefaultOption] = useState({});
  const [title, setTitle] = useState(`Level Up ${moment().format('YYYY-MM-DD')}`);
  const [reportVisible, setReportVisible] = useState(false);
  const classQuery = useQuery(queries.getClass.GET_TYPE_CLASS, {
    variables: {
      state: 'ing',
    },
  });
  useEffect(() => {
    if (classQuery) {
      classQuery.refetch();
    }
  }, []);
  const pastCheck = (date) => {
    const today = moment();
    return moment(today).isBefore(date);
  };
  const [workBooks, setWorkBooks] = useState([]);

  const [getSelectClassStudents, { data: fetchStudents }] = useLazyQuery(queries.getClass.CLASS_STUDENT_LIST);

  const [assignExternalStudyToStudents] = useMutation(mutations.assignExternalStudy.ASSIGN_EXTERNAL_STUDY_TO_STUDENTS, {
    onCompleted: () => {
      openNotification('등록 완료!');
      history.push('/level-up/assigned-grouplist');
    },
  });
  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
    if (fetchStudents?.classStudentList) {
      const index = fetchStudents?.classStudentList?.findIndex((item) => item.user.idx === parseInt(userIdx));
      const tmp = checkedList;
      tmp[index] = true;
      setCheckedList(tmp);
      setStudents(fetchStudents?.classStudentList);
    }

    if (classIdx && classIdx !== 'undefined') {
      if (!userIdx) {
        setCheckedList([]);
      }
      setSelectedClass(classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(classIdx))[0]);
      getSelectClassStudents({
        variables: { class_idx: parseInt(classIdx) },
      });
      firstForm.setFieldsValue({
        class: parseInt(classIdx),
      });
    }
  }, [classQuery, fetchStudents, classIdx, userIdx]);
  // useEffect(() => {
  //   if (classQuery && idx) {
  //     // const currentClass = classQuery?.data?.classList.filter((e)=>parseInt(e.idx)===parseInt(idx))
  //     setSelectedClass(classQuery.data.classList.filter((e)=>parseInt(e.idx)===parseInt(idx))[0]);
  //     getSelectClassStudents({
  //       variables: { class_idx: parseInt(idx) },
  //     });
  //     console.log(selectedClass,fetchStudents)
  //   }
  // }, [ idx,classQuery]);
  useEffect(() => {
    if (classQuery && companyName === 'englishtap') {
      setSelectedClass(classQuery?.data?.classList[0]);
    }
  }, [classQuery, companyName]);
  useEffect(() => {
    if (selectedClass?.idx && companyName === 'englishtap') {
      getSelectClassStudents({
        variables: { class_idx: selectedClass?.idx },
      });
    }
    if (selectedClass?.idx && classIdx && classIdx !== 'undefined') {
      getSelectClassStudents({
        variables: { class_idx: parseInt(classIdx) },
      });
    }
  }, [selectedClass, companyName, classIdx]);

  const filtered = useMemo(() => {
    if (classQuery?.data?.classList) {
      return classQuery?.data?.classList.filter((e) => pastCheck(e.end_date));
    }

    return [];
  }, [classQuery]);
  useEffect(() => {
    let selectedBooks = JSON.parse(localStorage.getItem('selectedBooks'));
    setListData(selectedBooks);
    setWorkBooks(selectedBooks);
  }, []);
  useEffect(() => {
    let tmp = [];
    if (workBooks.length > 0 && useBookReport) {
      tmp = workBooks
        .filter((item) => item.is_voca)
        .map((e) => {
          return {
            title: e?.title,
            idx: e?.idx,
            prompt: e?.ai_topic?.topic,
            level: 'Grade 1',
            sentences: 60,
            feedback: false,
            revise: false,
            copyPaste: false,
            version: 'short',
          };
        });
    }
    setOptions(tmp);
    setDefaultOption({
      level: tmp[0]?.level,
      sentences: tmp[0]?.sentences,
      feedback: tmp[0]?.feedback,
      revise: tmp[0]?.revise,
      copyPaste: tmp[0]?.copyPaste,
      version: tmp[0]?.version,
    });
  }, [useBookReport]);
  function handleDateSelect(selectInfo) {
    formControl.setFieldsValue({
      date: moment(selectInfo.dateStr),
    });
  }

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
    if (checkedList.every((e) => e === false)) {
      setStage(1);
    } else {
      setStage(2);
    }
  }

  function onCheckAllChange(e) {
    if (stage > 0) {
      if (e.target.checked) {
        setCheckedList(new Array(students.length).fill(true));
        setStage(2);
      } else {
        setCheckedList([]);
        setStage(1);
      }
    } else {
      alert('반 선택을 해주세요');
    }
  }
  function onCheckBookReportOption() {
    if (!useBookReport) {
      setUseBookReport(true);
    } else {
      setUseBookReport(false);
    }
  }
  function onCheckCalendareList(selectYoil) {
    setEvents(
      selectYoil.map((item, i) => {
        return {
          date: item,
          color: '#378006',
          title: `${i + 1}회차`,
          key: i,
        };
      }),
    );
  }

  function onCheckGenerateList(yoil) {
    const checkStudents = onCheckStudents();
    const newListData = workBooks
      ?.map((item, index) => {
        const obj = {
          days: yoil[index],
          lesson: `${item.title}`,
          No: `${index + 1}회차`,
          key: index + 1,
          idx: item?.idx,
          is_voca: item.is_voca,
        };
        return obj;
      })
      .filter((item) => !!item.days);

    checkStudents.length && setListData(newListData);
  }
  function handleChange(value) {
    setCheckedList([]);
    setSelectedClass(filtered?.filter((classInfo) => classInfo?.idx === value)[0]);
    getSelectClassStudents({
      variables: { class_idx: value },
    });
    setStage(1);
  }

  const onCheckStudents = (data) => {
    const astAdd = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    setSelectStudents(students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user));
    return students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user);
  };

  function onRangeChange(dates, dateStrings) {
    if (stage < 2) {
      alert('반, 학생을 선택해주세요');
      return;
    }
    setRangeDate({
      start: dateStrings[0],
      end: dateStrings[1],
    });

    if (selectedClass) {
      let dayOfWeek = customDayOfWeek(selectedClass);

      if (secondForm.getFieldValue('day_of_week')) {
        dayOfWeek = secondForm.getFieldValue('day_of_week').join(', ');
      } else if (!dayOfWeek) {
        dayOfWeek = '일, 월, 화, 수, 목, 금, 토';
      }

      const selectYoil = getDateRangeData(dateStrings[0], dateStrings[1], dayOfWeek);

      setSelectYoil(selectYoil);
      onCheckCalendareList(selectYoil);
      onCheckGenerateList(selectYoil);
      setStage(3);
    }
  }
  useEffect(() => {
    onCheckGenerateList(selectYoil);
  }, [selectYoil]);
  function onTimeChange(time, TimeStrings) {
    setTestTime(TimeStrings);
    setStage(4);
  }

  function getDateRangeData(param1, param2, dayOfWeek) {
    //param1은 시작일, param2는 종료일이다.
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const res_day = [];
    const ss_day = new Date(param1);
    const ee_day = new Date(param2);

    let count = 0;
    while (ss_day.getTime() <= ee_day.getTime()) {
      let _mon_ = ss_day.getMonth() + 1;
      _mon_ = _mon_ < 10 ? '0' + _mon_ : _mon_;

      let _day_ = ss_day.getDate();
      _day_ = _day_ < 10 ? '0' + _day_ : _day_;

      let yoil = ss_day.getDay();

      if (dayOfWeek.includes(week[yoil]) && workBooks[count]) {
        res_day.push(ss_day.getFullYear() + '-' + _mon_ + '-' + _day_);
        count++;
      }

      ss_day.setDate(ss_day.getDate() + 1);
    }
    return res_day;
  }
  const handleFormSubmit = () => {
    if (selectYoil.length < 1) {
      alert('You cannot save with 0 prompt.');
    } else {
      if (window.confirm('저장하시겠습니까?')) {
        if (selectYoil.length < workBooks.length) {
          var answer = window.confirm(`Do you want to go with ${selectYoil.length} books ?`);
          if (answer) {
            saveAssignedBooks();
          }
        } else {
          saveAssignedBooks();
        }
      } else {
        return;
      }
    }
  };
  const saveAssignedBooks = () => {
    let selectedWorkBooks = Array.from(workBooks);
    formControl
      .validateFields()
      .then(async (formData) => {
        await assignExternalStudyToStudents({
          variables: {
            class_idx: selectedClass.idx,
            start_time: selectYoil, // 클래스별 설정된 요일 별 날자 체크(요일 없을시 하루간격)
            limit_time: dueDate ? (dueDate - 1) * 1440 : 0,
            user_idx_list: selectStudents.map((students) => students.idx),
            external_data: selectedWorkBooks.splice(0, selectYoil.length).map((item, index) => {
              const current = options.filter((e) => e.idx === item.idx)[0];
              return item.is_voca && !isAfterSchool(companyName)
                ? {
                    ...item,
                    date: selectYoil[index],
                    time: testTime,
                    idx: item.book_no,
                    bookreport: useBookReport,
                    dueDate: dueDate,
                    version: useBookReport && current.version ? current.version : null,
                    sentences: useBookReport && current.sentences ? current.sentences : null,
                    feedback: useBookReport && current.feedback ? current.feedback : null,
                    revise: useBookReport && current.revise ? current.revise : null,
                    revisedPrompt: useBookReport && current.revisedPrompt ? current.revisedPrompt : null,
                    level: useBookReport && current.level ? current.level : null,
                    copyPaste: useBookReport && current.copyPaste ? current.copyPaste : null,
                  }
                : { ...item, date: selectYoil[index], time: testTime, idx: item.book_no, bookreport: useBookReport, dueDate: dueDate };
            }),
            start_date: rangeDate?.start, // 달력 시작일
            end_date: rangeDate?.end, // 달력 마지막일
            study_time: testTime,
            rowidx: null,
            study_type: 'levelup',
            dateList: '',
            title: title,
          },
        });
        firstForm.resetFields();
        formControl.resetFields();
        secondForm.resetFields();
        thirdForm.resetFields();
        fourthForm.resetFields();
        setClasses([]);
        setStudents([]);
        setSelectedClass(-1);
        setSelectYoil([]);
        setSelectStudents([]);
        setTestTime([]);
        setEvents([]);
        setListData([]);
        setRangeDate({ start: null, end: null });
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  function showUpdateConfirm(changeInfo) {
    handleEventChange(changeInfo);
  }

  function handleEventChange(changeInfo, type = '') {
    const {
      start,
      extendedProps: { key },
    } = changeInfo.event.toPlainObject();

    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item.date = start;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item = start;
        }
        return item;
      });
    });
  }

  function handleDateChange(date, changeDate, index) {
    const name = `testDate-${index}`;
    formControl.setFieldsValue({
      [name]: changeDate && moment(changeDate, 'YYYY-MM-DD'),
    });
    const searchIndex = index - 1;
    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === searchIndex) {
          item.date = changeDate;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === searchIndex) {
          item = changeDate;
        }
        return item;
      });
    });
  }

  const columns = useBookReport
    ? [
        {
          title: '#',
          dataIndex: 'No',
          key: 'No',
          align: 'center',
          width: 80,
        },
        {
          title: 'Book',
          dataIndex: 'lesson',
          key: 'lesson',
          align: 'center',
        },
        {
          title: '날짜',
          dataIndex: 'days',
          align: 'center',
          key: 'days',
          width: 200,
          render: (text, record) => {
            const names = `testDate-${record.key}`;
            formControl.setFieldsValue({
              [names]: text && moment(text, 'YYYY-MM-DD'),
            });
            return (
              <>
                <Form.Item style={{ marginBottom: 0 }} name={`testDate-${record.key}`}>
                  <DatePicker
                    name={`testDate-${record.key}`}
                    initialValues={text && moment(text, 'YYYY-MM-DD')}
                    //onChange={handleDateChange}

                    onChange={(date, dateString) => {
                      handleDateChange(date, dateString, record.key);
                    }}
                  />
                </Form.Item>
              </>
            );
          },
        },
        {
          title: 'Report Option',
          dataIndex: 'idx',
          align: 'center',
          key: 'idx',
          width: 120,
          render: (text, record) => {
            console.log(text, record);
            return (
              <>
                {record.is_voca ? (
                  <Button
                    onClick={() => {
                      handleOpenModal(text);
                    }}
                  >
                    Edit
                  </Button>
                ) : (
                  ''
                )}
              </>
            );
          },
        },
      ]
    : [
        {
          title: '#',
          dataIndex: 'No',
          key: 'No',
          align: 'center',
          width: 80,
        },
        {
          title: 'Book',
          dataIndex: 'lesson',
          key: 'lesson',
          align: 'center',
        },
        {
          title: '날짜',
          dataIndex: 'days',
          align: 'center',
          key: 'days',
          width: 200,
          render: (text, record) => {
            const names = `testDate-${record.key}`;
            formControl.setFieldsValue({
              [names]: text && moment(text, 'YYYY-MM-DD'),
            });
            return (
              <>
                <Form.Item name={`testDate-${record.key}`}>
                  <DatePicker
                    name={`testDate-${record.key}`}
                    initialValues={text && moment(text, 'YYYY-MM-DD')}
                    //onChange={handleDateChange}
                    onChange={(date, dateString) => {
                      handleDateChange(date, dateString, record.key);
                    }}
                  />
                </Form.Item>
              </>
            );
          },
        },
      ];
  const handleOpenModal = (idx) => {
    setReportVisible(true);
    setOptionidx(idx);
  };
  const handleCloseModal = () => {
    setReportVisible(false);
    setOptionidx(null);
  };
  return (
    <>
      <Col className="test-syllabus-wrapper" span={24} style={{ marginTop: '10px' }}>
        <TestBlock>
          <Row style={{ padding: 10 }}>
            <Title level={5}>
              <span style={{ color: '#289428' }}>Auto Assign</span>
            </Title>
          </Row>
          <Row className="test-syllabus-box-listing" gutter={[24, 16]}>
            <Col className="test-syllabus-box-a" span={5} style={{ paddingLeft: 20 }}>
              <TestSectionBlock className="test-syllabus-box-a-testsectionblock">
                <Title level={5}>&nbsp;</Title>
                <Form
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  layout="vertical"
                  form={firstForm}
                >
                  <Form.Item name="class" label="" style={{ marginBottom: 5 }}>
                    <Select
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: '100%', minWidth: 100 }}
                      placeholder="Class"
                      onChange={handleChange}
                    >
                      {filtered.map((classInfo) => (
                        <Option key={classInfo?.idx} value={classInfo?.idx}>
                          {classInfo?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <List
                    header={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label>Name</label>
                        <div>
                          전체 선택 &nbsp; <Checkbox disabled={stage < 1} onChange={onCheckAllChange}></Checkbox>
                        </div>
                      </div>
                    }
                    footer={<div></div>}
                    bordered
                    dataSource={students}
                    renderItem={(item, idx) => (
                      <List.Item style={{ display: 'flex' }}>
                        <span>{item.user.name}</span> <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
                      </List.Item>
                    )}
                  />
                </Form>
              </TestSectionBlock>
            </Col>
            <Col className="test-syllabus-box-b" span={10} style={{ textAlign: 'center', paddingRight: 20 }}>
              <TestSectionBlock>
                <Form form={secondForm}>
                  <Title level={5}>{isAfterSchool(companyName) ? 'Homework' : 'Study'} Date / Time</Title>
                  <FormItem name="day_of_week">
                    <Checkbox.Group options={yoilOptions} name="day_of_week" />
                  </FormItem>
                  <div
                    onClick={() => {
                      if (stage < 2) alert('학생 선택을 완료해주세요');
                    }}
                  >
                    <RangePicker style={{ marginBottom: 10 }} onChange={onRangeChange} name="rangeDate" disabled={stage < 2} />
                  </div>
                </Form>
                <CalendarBlock className="mini-calander">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    contentHeight={250}
                    expandRows={false}
                    initialView="dayGridMonth"
                    selectable={true}
                    events={events}
                    editable={true}
                    eventContent={(eventInfo, index) => {
                      return (
                        <>
                          <div
                            style={{
                              backgroundColor: eventInfo.backgroundColor,
                              color: 'white',
                              borderRadius: 10,
                              width: 20,
                              textAlign: 'center',
                              marginRight: 5,
                            }}
                          >
                            {eventInfo.event.title}
                          </div>
                        </>
                      );
                    }}
                    eventChange={showUpdateConfirm}
                  />
                </CalendarBlock>

                <Form
                  className="assign-book"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  layout="horizontal"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  form={thirdForm}
                >
                  {!isAfterSchool(companyName) && (
                    // <Checkbox checked={useBookReport} onChange={(e) => onCheckBookReportOption(e)} disabled={stage < 3}>
                    //   Book Report
                    // </Checkbox>
                    <div>
                      Book Report:&nbsp;
                      <Switch checked={useBookReport} onClick={onCheckBookReportOption} disabled={stage < 3} />
                    </div>
                  )}
                  <Form.Item label="Time">
                    <DatePicker
                      picker="time"
                      size="large"
                      format="HH:mm"
                      inputReadOnly={true}
                      showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                      onChange={onTimeChange}
                      disabled={stage < 3}
                      onClick={() => {
                        if (stage < 2) {
                          alert('학생 선택을 완료해주세요');
                        } else if (stage < 3) {
                          alert('날짜 선택을 완료해주세요');
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Due Date">
                    <Select
                      style={{
                        alignItems: 'center',
                        width: '100px',
                        // maxWidth: '100%',
                        border: '1px solid #d9d9d9',
                        backgroundColor: '#fff',
                      }}
                      placeholder={` Select `}
                      onChange={(e) => {
                        setDueDate(e);
                      }}
                      bordered={false}
                    >
                      {[1, 2, 3, 4, 5, 6].map((e) => (
                        <Option key={e} value={e}>
                          {e}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              </TestSectionBlock>
            </Col>

            <Col className="test-syllabus-box-c" span={9} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <RowFlex style={{ width: '100%', justifyContent: 'space-between' }}>
                <Title level={5}>Confirmation</Title>
                {useBookReport && (
                  <Button
                    onClick={() => {
                      handleOpenModal(null);
                    }}
                  >
                    Book Report Option
                  </Button>
                )}
              </RowFlex>
              <Form form={formControl} component={false}>
                <CustomTable
                  dataSource={listData}
                  columns={columns}
                  bordered
                  scroll={{ y: 'calc(100vh - 400px)' }}
                  pagination={{ pageSize: 10 }}
                  size="small"
                  color="#edf3fb"
                />

                <Form.Item label="Title" style={{ marginBottom: 0 }}>
                  <Input
                    style={{
                      alignItems: 'center',
                      width: '100%',
                      maxWidth: '100%',
                      marginBottom: '10px',
                      border: '1px solid #d9d9d9',
                      backgroundColor: '#fff',
                    }}
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    bordered={false}
                  />
                </Form.Item>

                <div>
                  <Button
                    type="secondary"
                    shape="round"
                    size="large"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                  <Button type="primary" shape="round" size="large" htmlType="submit" onClick={handleFormSubmit}>
                    Save
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </TestBlock>
      </Col>
      <BookReportOptionModal
        title={<div style={{ fontSize: '20px', color: '#000000' }}>Book Report Option</div>}
        visible={reportVisible}
        onCancel={handleCloseModal}
        footer={null}
        // width={900}
      >
        <BookReportOptions
          optionidx={optionidx}
          options={options}
          setOptions={setOptions}
          close={handleCloseModal}
          defaultOption={defaultOption}
          setDefaultOption={setDefaultOption}
        />
      </BookReportOptionModal>
    </>
  );
};

export default AssignLevelUp;

const BookReportOptions = ({ close, options, setOptions, optionidx, defaultOption, setDefaultOption }) => {
  const [prompt, setPrompt] = useState(null);
  const [level, setLevel] = useState('Grade 1');
  const [version, setVersion] = useState('short');
  const [sentences, setSentences] = useState(60);
  const [feedback, setFeedback] = useState(false);
  const [revise, setRevise] = useState(false);
  const [copyPaste, setCopyPaste] = useState(false);
  const currentData = options.filter((e) => e.idx === optionidx)[0];
  useEffect(() => {
    if (currentData && currentData?.edited) {
      setLevel(currentData.level);
      setVersion(currentData.version);
      setSentences(currentData.sentences);
      setFeedback(currentData.feedback);
      setRevise(currentData.revise);
      setCopyPaste(currentData.copyPaste);
    } else {
      setLevel(defaultOption.level);
      setVersion(defaultOption.version);
      setSentences(defaultOption.sentences);
      setFeedback(defaultOption.feedback);
      setRevise(defaultOption.revise);
      setCopyPaste(defaultOption.copyPaste);
    }
  }, [defaultOption, currentData]);
  const handleSave = () => {
    const tmp = _.cloneDeep(options);

    if (optionidx) {
      const currentidx = tmp.findIndex((e) => e.idx === optionidx);
      tmp[currentidx] = {
        ...tmp[currentidx],
        feedback: feedback,
        sentences: sentences,
        level: level,
        revise: revise,
        revisedPrompt: prompt,
        version: version,
        copyPaste: copyPaste,
        edited: true,
      };
      setOptions(tmp);
    } else {
      const tmp2 = tmp.map((e) => {
        return { ...e, feedback: feedback, sentences: sentences, level: level, revise: revise, copyPaste: copyPaste, edited: false };
      });
      setOptions(tmp2);
      setDefaultOption(tmp2[0]);
    }
    close();
  };
  return (
    <ModalWrapper>
      <RowFlex style={{ height: '100%', justifyContent: 'space-between', alignItems: 'stretch', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flex: 1,
            paddingRight: '20px',
            // height:'100%',
            minHeight: '200px',
            width: '70%',
            // borderRight: '1px solid #5a789a',
            marginRight: '20px',
          }}
        >
          {optionidx && (
            <>
              <RowFlex style={{ padding: '0 0 12px', width: '100%' }}>
                <ModalLabel style={{ width: '100px', minWidth: '100px' }}>Assignment :</ModalLabel>
                <Form.Item style={{ marginBottom: 0, width: '100%' }}>
                  <Input placeholder="Topic" value={currentData?.title} disabled />
                </Form.Item>
              </RowFlex>
              <ColumnFlex>
                <ModalLabel style={{ paddingBottom: '9px' }}>Topic / Prompt :</ModalLabel>
                <Form.Item style={{ marginBottom: 0 }} name="prompt">
                  <Input.TextArea
                    placeholder={currentData?.prompt || 'Prompt'}
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                  />
                </Form.Item>
              </ColumnFlex>
            </>
          )}
          {/* <ModalLabel style={{ color: '#2E75B6', padding: '10px 0 5px 0' }}>Student</ModalLabel> */}
          <RowFlex style={{ justifyContent: 'space-between', width: '100%' }}>
            <RowFlex>
              <ModalLabel style={{ width: '130px' }}>Level :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '135px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={level}
                  onChange={(e) => {
                    setLevel(e);
                  }}
                  bordered={false}
                >
                  {levelList.map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
            <RowFlex style={{ justifyContent: 'space-between' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Min. Words :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={sentences}
                  onChange={(e) => {
                    setSentences(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: 60, label: 'Min 60 words' },
                    { value: 100, label: 'Min 100 words' },
                    {
                      value: 150,
                      label: 'Min 150 words',
                    },
                    {
                      value: 250,
                      label: 'Min 250 words',
                    },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>
          <RowFlex style={{ justifyContent: 'space-between', width: '100%' }}>
            <RowFlex>
              <ModalLabel style={{ width: '130px' }}>Report :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '135px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={version}
                  onChange={(e) => {
                    setVersion(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: 'long', label: 'Long Ver.' },
                    { value: 'short', label: 'Short Ver.' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
            <RowFlex style={{ justifyContent: 'space-between' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Feedback Button :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={feedback}
                  onChange={(e) => {
                    setFeedback(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>

          <RowFlex style={{ width: '100%', justifyContent: 'space-between' }}>
            <RowFlex>
              <ModalLabel style={{ width: '130px' }}>Copy & Paste :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '135px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={copyPaste}
                  onChange={(e) => {
                    setCopyPaste(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>

            <RowFlex style={{ minWidth: '250px', justifyContent: 'space-between' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Revise Button :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={revise}
                  onChange={(e) => {
                    setRevise(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>
          {/* <ColumnFlex>
            <RowFlex>
              &nbsp; &nbsp; &nbsp;
              <Form.Item style={{ marginBottom: 0, width: '50%' }}>
                <Select
                  style={{ alignItems: 'center', width: '200px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  placeholder={` Due Date `}
                  onChange={(e) => {
                    setDueDate(e);
                  }}
                  bordered={false}
                >
                  {[1, 2, 3, 4, 5, 6].map((e) => (
                    <Option key={e} value={e}>
                      {e}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </ColumnFlex> */}
        </div>
      </RowFlex>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <Button style={{ marginRight: 10 }} onClick={close}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleSave}>
          {' '}
          Save{' '}
        </Button>
      </div>
    </ModalWrapper>
  );
};

const ColumnFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const ModalWrapper = styled(ColumnFlex)`
  min-height: 350px;
  justify-content: space-between;
`;

const BookReportOptionModal = styled(Modal)`
  .ant-modal-header {
    background-color: #ffda00;
    border-radius: 20px 20px 0 0;
  }
  .ant-modal-content {
    border: 3px solid #fff2cc;
    border-radius: 20px;
    background-color: #fff;
  }
  width: 860px;
  min-width: 860px;
  @media (max-width: 900px) {
    min-width: 750px;
    width: 750px;
  }
`;
const ModalLabel = styled.div`
  color: #000000;
  font-weight: bold;
  padding-right: 10px;
`;
