import React, { useState, useMemo } from 'react';
import { Col, Row, Radio, Button } from 'antd';
import LevelTab, { useLevelTab } from './LevelTab';
import {
  BookCard,
  BookCover,
  BookMeta,
  BookTitle,
  BOOK_COL_STYLE,
  SMART_ECLASS_LINK_BUTTON_STYLE,
  SMART_ECLASS_CDN_BASE_URL,
  SNEAKERS_CDN_BASE_URL,
} from './CommonComp';

import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;

const GROUPS = ['SB', 'WB'];
const LEVELS = [1, 2, 3, 4, 5, 6];
const VOLUMES = [1, 2, 3, 4, 5, 6];

//스마트이클래스, 스니커즈 공통으로 사용함..어쩔 수..
const IMAGE_NAMES_SM = [
  {
    title: 'SMART E Class',
    name: 'smart-e-class_',
    // link_name: 'http://contrepo.p-turn.com/smarteclass_ebook/SB/SmartEclass_*-#/',
    // link_name_old: 'http://contrepo.p-turn.com/smarteclass_ebook/SmartEclass_*-#/',
    link_name_new: 'https://online.flipbuilder.com/sangbong91/',
    additional: {
      lv1: ['cotb', 'yxua', 'besu', 'kmds', 'ocum', 'hizb'],
      lv2: ['prig', 'kaqi', 'yaug', 'dmux', 'fsli', 'jovd'],
      lv3: ['uzah', 'zeka', 'xfhc', 'osqr', 'spaw', 'ngeh'],
      lv4: ['rmqo', 'cygr', 'ubkz', 'lbew', 'gypd', 'vjml'],
      lv5: ['kgmc', 'djrn', 'fijk', 'hcwr', 'gqtm', 'mwhv'],
      lv6: ['foxd', 'kqpa', 'qsji', 'sywa', 'jgod', 'cemk'],
    },
  },
  {
    title: 'SMART E Class',
    name: 'smart-e-class_wb_',
    // link_name: 'http://contrepo.p-turn.com/smarteclass_ebook/WB/SmartEclass_*-#/',
    // link_name_old: 'http://contrepo.p-turn.com/smarteclass_ebook/SmartEclass_*-#/',
    link_name_new: 'https://online.flipbuilder.com/sangbong91/',
    additional: {
      lv1: ['dwvn', 'xirk', 'gasn', 'efun', 'gmau', 'xbei'],
      lv2: ['jirk', 'cejd', 'vsnu', 'kljx', 'fskq', 'jvag'],
      lv3: ['acgw', 'qlih', 'tdnl', 'knqz', 'zapn', 'mbgc'],
      lv4: ['kygj', 'afzi', 'lxiv', 'fznq', 'hnfc', 'bjds'],
      lv5: ['jktw', 'gmvj', 'bogc', 'sceh', 'bnge', 'jbwc'],
      lv6: ['gatr', 'jhfn', 'inzs', 'fost', 'wlvz', 'mqbn'],
    },
  },
];

const IMAGE_NAMES_SK = [
  {
    title: 'SNEAKERS',
    name: 'sneakers_',
    // link_name: 'http://contrepo.p-turn.com/smarteclass_ebook/SB/SmartEclass_*-#/',
    // link_name_old: 'http://contrepo.p-turn.com/smarteclass_ebook/SmartEclass_*-#/',
    link_name_new: 'https://online.flipbuilder.com/sangbong91/',
    additional: {
      lv1: ['cotb', 'yxua', 'besu', 'kmds', 'ocum', 'hizb'],
      lv2: ['prig', 'kaqi', 'yaug', 'dmux', 'fsli', 'jovd'],
      lv3: ['uzah', 'zeka', 'xfhc', 'osqr', 'spaw', 'ngeh'],
      lv4: ['rmqo', 'cygr', 'ubkz', 'lbew', 'gypd', 'vjml'],
      lv5: ['kgmc', 'djrn', 'fijk', 'hcwr', 'gqtm', 'mwhv'],
      lv6: ['foxd', 'kqpa', 'qsji', 'sywa', 'jgod', 'cemk'],
    },
  },
  {
    title: 'SNEAKERS',
    name: 'sneakers_wb_',
    // link_name: 'http://contrepo.p-turn.com/smarteclass_ebook/WB/SmartEclass_*-#/',
    // link_name_old: 'http://contrepo.p-turn.com/smarteclass_ebook/SmartEclass_*-#/',
    link_name_new: 'https://online.flipbuilder.com/sangbong91/',
    additional: {
      lv1: ['dwvn', 'xirk', 'gasn', 'efun', 'gmau', 'xbei'],
      lv2: ['jirk', 'cejd', 'vsnu', 'kljx', 'fskq', 'jvag'],
      lv3: ['acgw', 'qlih', 'tdnl', 'knqz', 'zapn', 'mbgc'],
      lv4: ['kygj', 'afzi', 'lxiv', 'fznq', 'hnfc', 'bjds'],
      lv5: ['jktw', 'gmvj', 'bogc', 'sceh', 'bnge', 'jbwc'],
      lv6: ['gatr', 'jhfn', 'inzs', 'fost', 'wlvz', 'mqbn'],
    },
  },
];

const SmartEbook = ({ checkForEduco = false }) => {
  const companyName = useSelector(classStoreData);
  const IMAGE_NAMES = useMemo(() => {
    if (companyName === 'smart-eclass') {
      return IMAGE_NAMES_SM;
    }
    return IMAGE_NAMES_SK;
  }, [companyName]);

  const [groupNo, setGroupNo] = useState(0);
  const { levelNo, onChangeLevelNo } = useLevelTab();
  const handleChangeGroup = (evt) => {
    const {
      target: { value },
    } = evt;
    setGroupNo(value);
    // onChangeLevelNo(0);
  };
  const onClickBookCard = (lv, vol) => {
    // if (lv > 3) {
    window.open(`${IMAGE_NAMES[groupNo].link_name_new}${IMAGE_NAMES[groupNo].additional[`lv${lv}`][vol - 1]}`, '_blank');
    // } else {
    //   const tmplink = IMAGE_NAMES[groupNo].link_name;
    //   window.open(`${tmplink.replace('*', lv).replace('#', vol)}`, '_blank', 'width=1400, height=900');
    // }
  };
  return (
    <>
      <div style={{ height: '72vh', overflowY: 'scroll', overflowX: 'hidden' }}>
        <LevelTab
          levelNo={levelNo}
          levels={LEVELS}
          onChage={(evt) => {
            const {
              target: { value },
            } = evt;
            onChangeLevelNo(value);
            setGroupNo(0);
          }}
        />
        <Radio.Group
          value={groupNo}
          style={{ marginBottom: 16, marginLeft: 16 }}
          buttonStyle="solid"
          onChange={handleChangeGroup}
          size="middle"
        >
          {GROUPS.map((groupName, key) => (
            <Radio.Button key={`radio-level-key${key}`} value={key} style={{ width: 160, textAlign: 'center' }}>
              {groupName}
            </Radio.Button>
          ))}
        </Radio.Group>
        {LEVELS.filter((level) => (levelNo === 0 ? true : level === levelNo)).map(
          (lv) => (
            <Row key={`row-${lv}`} gutter={[16, 16]} style={{ margin: '0 10px' }}>
              {VOLUMES.map((vol) => (
                <Col
                  span={4}
                  key={`col-${lv}-${vol}`}
                  style={{
                    ...BOOK_COL_STYLE,
                    borderRight: `${vol === 6 ? '1px solid #dedede' : ''}`,
                  }}
                >
                  <BookTitle level={5} style={{ textAlign: 'center' }}>
                    {`${IMAGE_NAMES[groupNo].title} ${lv}-${vol}`}
                  </BookTitle>
                  <BookCard
                    onClick={() => {
                      //임시
                      onClickBookCard(lv, vol);
                      //
                    }}
                    cover={
                      <BookCover
                        style={
                          groupNo === 0
                            ? // sb
                              {
                                backgroundImage: `url(${
                                  companyName === 'smart-eclass' ? SMART_ECLASS_CDN_BASE_URL : SNEAKERS_CDN_BASE_URL
                                }cover/L${lv}V${vol}.jpg)`,
                              }
                            : // wb
                              {
                                backgroundImage: `url(${
                                  companyName === 'smart-eclass' ? SMART_ECLASS_CDN_BASE_URL : SNEAKERS_CDN_BASE_URL
                                }cover/L${lv}V${vol}-WB.jpg)`,
                              }
                        }
                      />
                    }
                  >
                    {groupNo === 0 && !checkForEduco && (
                      <BookMeta description={<EbookLinkGroup level={lv} volume={vol} companyName={companyName} />} />
                    )}
                  </BookCard>
                </Col>
              ))}
            </Row>
          ),
          // ),
        )}
      </div>
    </>
  );
};

export default SmartEbook;

const EbookLinkGroup = ({ level, volume, companyName }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, paddingBottom: 5 }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          const ppt_download_link =
            companyName === 'sneakersedu'
              ? `${SNEAKERS_CDN_BASE_URL}common/sneakers/Sneakers_${level}-${volume}_teacher_ppt.zip`
              : `${SMART_ECLASS_CDN_BASE_URL}common/smarteclass/Smart_E_class_Level_${level}-${volume}_teacher_ppt.zip`;
          window.open(ppt_download_link);
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.4em', lineHeight: '1' }}
      >
        PPT <br />
        Download
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          const culp_token = window.localStorage.getItem('culp_token');
          const root_book_code = companyName === 'sneakersedu' ? 'SK' : 'SM';
          window.open(`${STUDY_TOOL_HOST}/${root_book_code}-L${level}V${volume}U1D1?token=${culp_token}`, '_blank');
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.6em' }}
      >
        E-learning
      </Button>
    </div>
  );
};
