import React, { useState, useEffect, useMemo } from 'react';
import { Col, Typography, Form, List, Modal, Row, Button, Input } from 'antd';
import { withRouter, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TEST_CATEGORY_LIST } from 'src/operations/queries/getTests';
import { userInfoVar } from 'src/apollo/cache';
import { useQuery, useMutation } from '@apollo/client';
import { DeleteFilled, EditFilled, PlusCircleFilled } from '@ant-design/icons';
import { CREATE_TEST_CATEGORY, UPDATE_TEST_CATEGORY, DELETE_TEST_CATEGORY } from 'src/operations/mutations/createTests';
const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;
const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;
const MainPage = (props) => {
  const history = useHistory();
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [createTestCategory] = useMutation(CREATE_TEST_CATEGORY);
  const [updateTestCategory] = useMutation(UPDATE_TEST_CATEGORY);
  const [deleteTestCategory] = useMutation(DELETE_TEST_CATEGORY);
  const { data: categoryData, refetch } = useQuery(TEST_CATEGORY_LIST, {
    fetchPolicy: 'no-cache',
    variables:
      userInfoVar()?.type !== 5
        ? {
            company_idx: userInfoVar()?.campus?.fc_company_idx,
          }
        : {},
  });

  let categoryList = useMemo(() => {
    if (categoryData) {
      return categoryData?.testCategoryList;
    }
    return [];
  }, [categoryData]);

  let categoryListSub = useMemo(() => {
    if (selectedMainCategory !== null) {
      return categoryList[selectedMainCategory]?.other_test_category || [];
    }
    return [];
  }, [categoryList, selectedMainCategory]);

  let categoryListSubSub = useMemo(() => {
    if (selectedSubCategory !== null) {
      return categoryListSub[selectedSubCategory]?.other_test_category || [];
    }
    return [];
  }, [categoryListSub, selectedSubCategory]);

  const handleCreateCategory = async (name, parent_id) => {
    try {
      await createTestCategory({ variables: { name, parent_id } });
      Modal.info({
        title: 'Category Added',
        onOk() {
          refetch();
          Modal.destroyAll();
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdateCategory = async (idx, name) => {
    try {
      await updateTestCategory({ variables: { idx, name } });
      Modal.info({
        title: 'Category Updated',
        onOk() {
          refetch();
          Modal.destroyAll();
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteCategory = async (idx) => {
    try {
      await deleteTestCategory({ variables: { idx } });
      Modal.info({
        title: 'Category Deleted',
        onOk() {
          refetch();
          Modal.destroyAll();
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}>
        <MenuButton
          type="default"
          onClick={() => {
            history.push('/test/manage/list');
          }}
        >
          Test Management
        </MenuButton>
        &nbsp;
        <MenuButton
          type={'primary'}
          onClick={() => {
            history.push('/test/category');
          }}
        >
          Test Category
        </MenuButton>
      </div>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testmanagement-mailblock">
          <Row gutter={[24, 8]} style={{ margin: 0 }}>
            <Col className="contenttest-title" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>Test Category</Title>
            </Col>
          </Row>
          <Row gutter={[24, 8]} style={{ margin: 0 }}>
            <Col span={8} xs={8} md={8} lg={8}>
              <List
                itemLayout="horizontal"
                dataSource={categoryList}
                header={
                  <div>
                    Category
                    <PlusCircleFilled
                      style={{ marginLeft: 10, cursor: 'pointer' }}
                      onClick={() => {
                        Modal.info({
                          closable: true,
                          okButtonProps: { style: { display: 'none' } },
                          title: 'Add Category',
                          content: (
                            <div>
                              <Form
                                onFinish={(values) => {
                                  handleCreateCategory(values.name, null);
                                }}
                              >
                                <Form.Item name="name">
                                  <Input placeholder="Category Name" />
                                </Form.Item>
                                <Form.Item>
                                  <Button type="primary" htmlType="submit">
                                    Add
                                  </Button>
                                </Form.Item>
                              </Form>
                            </div>
                          ),
                        });
                      }}
                    />
                  </div>
                }
                renderItem={(item, index) => (
                  <List.Item
                    style={{ cursor: 'pointer', backgroundColor: selectedMainCategory === index ? '#f0f0f0' : 'white' }}
                    onClick={() => {
                      setSelectedMainCategory(index);
                    }}
                    key={item.idx}
                  >
                    <Typography.Text
                      style={{
                        color: selectedMainCategory === index ? 'blue' : 'black',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      {item.name}
                      <div>
                        <EditFilled
                          style={{ marginLeft: 10, cursor: 'pointer' }}
                          onClick={() => {
                            Modal.info({
                              closable: true,
                              okButtonProps: { style: { display: 'none' } },
                              title: 'Edit Category',
                              content: (
                                <div>
                                  <Form
                                    onFinish={(values) => {
                                      handleUpdateCategory(item.idx, values.name);
                                    }}
                                  >
                                    <Form.Item name="name">
                                      <Input placeholder="Category Name" defaultValue={item.name} />
                                    </Form.Item>
                                    <Form.Item>
                                      <Button type="primary" htmlType="submit">
                                        Update
                                      </Button>
                                    </Form.Item>
                                  </Form>
                                </div>
                              ),
                            });
                          }}
                        />
                        <DeleteFilled
                          style={{ marginLeft: 10, cursor: 'pointer' }}
                          onClick={() => {
                            Modal.info({
                              title: 'Delete Category',
                              content: (
                                <div>
                                  <p>Are you sure you want to delete this category?</p>
                                </div>
                              ),
                              onOk() {
                                handleDeleteCategory(item.idx);
                              },
                            });
                          }}
                        />
                      </div>
                    </Typography.Text>
                  </List.Item>
                )}
                bordered
                size="large"
              />
            </Col>

            <Col span={8} xs={8} md={8} lg={8}>
              <List
                itemLayout="horizontal"
                dataSource={categoryListSub}
                header={
                  <div>
                    Sub Category
                    <PlusCircleFilled
                      style={{ marginLeft: 10, cursor: 'pointer' }}
                      onClick={() => {
                        Modal.info({
                          closable: true,
                          okButtonProps: { style: { display: 'none' } },
                          title: 'Add Sub Category',
                          content: (
                            <div>
                              <Form
                                onFinish={(values) => {
                                  handleCreateCategory(values.name, categoryList[selectedMainCategory].idx);
                                }}
                              >
                                <Form.Item name="name">
                                  <Input placeholder="Sub Category Name" />
                                </Form.Item>
                                <Form.Item>
                                  <Button type="primary" htmlType="submit">
                                    Add
                                  </Button>
                                </Form.Item>
                              </Form>
                            </div>
                          ),
                        });
                      }}
                    />
                  </div>
                }
                renderItem={(item, index) => (
                  <List.Item
                    style={{ cursor: 'pointer', backgroundColor: selectedSubCategory === index ? '#f0f0f0' : 'white' }}
                    onClick={() => {
                      setSelectedSubCategory(index);
                    }}
                    key={item.idx}
                  >
                    <Typography.Text
                      style={{
                        color: selectedSubCategory === index ? 'blue' : 'black',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      {item.name}

                      <div>
                        <EditFilled
                          style={{ marginLeft: 10, cursor: 'pointer' }}
                          onClick={() => {
                            Modal.info({
                              closable: true,
                              okButtonProps: { style: { display: 'none' } },
                              title: 'Edit Category',
                              content: (
                                <div>
                                  <Form
                                    onFinish={(values) => {
                                      handleUpdateCategory(item.idx, values.name);
                                    }}
                                  >
                                    <Form.Item name="name">
                                      <Input placeholder="Category Name" defaultValue={item.name} />
                                    </Form.Item>
                                    <Form.Item>
                                      <Button type="primary" htmlType="submit">
                                        Update
                                      </Button>
                                    </Form.Item>
                                  </Form>
                                </div>
                              ),
                            });
                          }}
                        />
                        <DeleteFilled
                          style={{ marginLeft: 10, cursor: 'pointer' }}
                          onClick={() => {
                            Modal.info({
                              title: 'Delete Category',
                              content: (
                                <div>
                                  <p>Are you sure you want to delete this category?</p>
                                </div>
                              ),
                              onOk() {
                                handleDeleteCategory(item.idx);
                              },
                            });
                          }}
                        />
                      </div>
                    </Typography.Text>
                  </List.Item>
                )}
                bordered
                size="large"
              />
            </Col>
            <Col span={8} xs={8} md={8} lg={8}>
              <List
                itemLayout="horizontal"
                dataSource={categoryListSubSub}
                header={
                  <div>
                    Sub Sub Category
                    <PlusCircleFilled
                      style={{ marginLeft: 10, cursor: 'pointer' }}
                      onClick={() => {
                        Modal.info({
                          closable: true,
                          okButtonProps: { style: { display: 'none' } },
                          title: 'Add Sub Sub Category',
                          content: (
                            <div>
                              <Form
                                onFinish={(values) => {
                                  handleCreateCategory(values.name, categoryListSub[selectedSubCategory].idx);
                                }}
                              >
                                <Form.Item name="name">
                                  <Input placeholder="Sub Sub Category Name" />
                                </Form.Item>
                                <Form.Item>
                                  <Button type="primary" htmlType="submit">
                                    Add
                                  </Button>
                                </Form.Item>
                              </Form>
                            </div>
                          ),
                        });
                      }}
                    />
                  </div>
                }
                renderItem={(item) => (
                  <List.Item key={item.idx}>
                    <Typography.Text
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      {item.name}
                      <div>
                        <EditFilled
                          style={{ marginLeft: 10, cursor: 'pointer' }}
                          onClick={() => {
                            Modal.info({
                              closable: true,
                              okButtonProps: { style: { display: 'none' } },
                              title: 'Edit Category',
                              content: (
                                <div>
                                  <Form
                                    onFinish={(values) => {
                                      handleUpdateCategory(item.idx, values.name);
                                    }}
                                  >
                                    <Form.Item name="name">
                                      <Input placeholder="Category Name" defaultValue={item.name} />
                                    </Form.Item>
                                    <Form.Item>
                                      <Button type="primary" htmlType="submit">
                                        Update
                                      </Button>
                                    </Form.Item>
                                  </Form>
                                </div>
                              ),
                            });
                          }}
                        />
                        <DeleteFilled
                          style={{ marginLeft: 10, cursor: 'pointer' }}
                          onClick={() => {
                            Modal.info({
                              title: 'Delete Category',
                              content: (
                                <div>
                                  <p>Are you sure you want to delete this category?</p>
                                </div>
                              ),
                              onOk() {
                                handleDeleteCategory(item.idx);
                              },
                            });
                          }}
                        />
                      </div>
                    </Typography.Text>
                  </List.Item>
                )}
                bordered
                size="large"
              />
            </Col>
          </Row>
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(MainPage);
