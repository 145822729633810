import { gql } from '@apollo/client';

export const CREATE_TEST_DOCUMENT = gql`
  mutation createTestDocument(
    $code: String!
    $level: String
    $volume: String
    $unit: String
    $title: String!
    $meta: [String!]!
    $main_pdf: String
    $questions: [JSONObject!]!
    $extra_data: JSONObject
    $total_questions: Int!
    $class_idx: Int
    $folder_type: String
    $type: Int
  ) {
    createTestDocument(
      code: $code
      level: $level
      volume: $volume
      unit: $unit
      title: $title
      meta: $meta
      main_pdf: $main_pdf
      questions: $questions
      extra_data: $extra_data
      total_questions: $total_questions
      class_idx: $class_idx
      folder_type: $folder_type
      type: $type
    ) {
      idx
    }
  }
`;

export const EDIT_TEST_DOCUMENT = gql`
  mutation updateTestDocument(
    $idx: Int!
    $code: String!
    $level: String
    $volume: String
    $unit: String
    $title: String!
    $meta: [String!]!
    $main_pdf: String
    $questions: [JSONObject!]!
    $extra_data: JSONObject
    $total_questions: Int!
    $class_idx: Int
    $folder_type: String
    $type: Int
  ) {
    updateTestDocument(
      idx: $idx
      code: $code
      level: $level
      volume: $volume
      unit: $unit
      title: $title
      meta: $meta
      main_pdf: $main_pdf
      questions: $questions
      extra_data: $extra_data
      total_questions: $total_questions
      class_idx: $class_idx
      folder_type: $folder_type
      type: $type
    ) {
      idx
    }
  }
`;

export const EDIT_TEST = gql`
  mutation updateTest(
    $idx: Int!
    $code: String
    $title: String
    $meta: [String!]
    $answers: [JSONObject!]
    $main_pdf_idx: Int
    $extra_pdf_idx: Int
    $extra_audio_idx: Int
    $extra_video_idx: Int
    $total_questions: Int
    $class_idx: Int
  ) {
    updateTest(
      idx: $idx
      code: $code
      title: $title
      meta: $meta
      answers: $answers
      main_pdf_idx: $main_pdf_idx
      extra_pdf_idx: $extra_pdf_idx
      extra_audio_idx: $extra_audio_idx
      extra_video_idx: $extra_video_idx
      total_questions: $total_questions
      class_idx: $class_idx
    ) {
      idx
    }
  }
`;

export const TEST_DELETE = gql`
  mutation deleteTest($idxs: [Int!]!) {
    deleteTest(idxs: $idxs)
  }
`;

export const ASSIGN_TEST = gql`
  mutation assignTests($title: String!, $start_time: String!, $limit_time: Int!, $user_idx_list: [Int!]!, $class_idx: Int, $test_idx: Int) {
    assignTests(
      title: $title
      start_time: $start_time
      limit_time: $limit_time
      test_idx: $test_idx
      user_idx_list: $user_idx_list
      class_idx: $class_idx
    ) {
      idx
    }
  }
`;

export const ASSIGN_TEST_EDIT = gql`
  mutation updateAssignedTest(
    $idx: Int!
    $title: String!
    $start_time: String!
    $limit_time: Int!
    $user_idx_list: [Int!]!
    $class_idx: Int
  ) {
    updateAssignedTest(
      idx: $idx
      title: $title
      start_time: $start_time
      limit_time: $limit_time
      user_idx_list: $user_idx_list
      class_idx: $class_idx
    ) {
      idx
    }
  }
`;

export const ASSIGN_TEST_DELETE = gql`
  mutation deleteAssignedTest($idxs: [Int!]!) {
    deleteAssignedTest(idxs: $idxs)
  }
`;

export const SAVE_TEST_ANSWERS = gql`
  mutation saveTestAnswers(
    $answers: [JSONObject!]!
    $start_time: String!
    $end_time: String!
    $score: Float!
    $assigned_test_user_idx: Int!
  ) {
    saveTestAnswers(
      answers: $answers
      start_time: $start_time
      end_time: $end_time
      score: $score
      assigned_test_user_idx: $assigned_test_user_idx
    ) {
      idx
    }
  }
`;

export const CREATE_TEST_GROUP = gql`
  mutation createTestGroup($code: String!, $title: String!, $folderType: String!, $files: [Upload!], $test_category_idx: Int) {
    createTestGroup(code: $code, title: $title, folderType: $folderType, files: $files, test_category_idx: $test_category_idx) {
      code
      title
      meta
      type
      test_category_idx
      main_pdf {
        idx
      }
      extra_pdf {
        idx
      }
      extra_audio {
        idx
      }
      extra_video {
        idx
      }
      total_questions
      test_group {
        name
      }
      user {
        idx
        name
      }
    }
  }
`;

export const ASSIGN_TEST_GROUP = gql`
  mutation assignTestsGroup(
    $class_idx: Int!
    $start_time: [String!]!
    $limit_time: Int!
    $user_idx_list: [Int!]!
    $test_group_idx: Int!
    $start_date: String!
    $end_date: String!
    $workbook_time: String!
  ) {
    assignTestsGroup(
      class_idx: $class_idx
      start_time: $start_time
      limit_time: $limit_time
      user_idx_list: $user_idx_list
      test_group_idx: $test_group_idx
      start_date: $start_date
      end_date: $end_date
      workbook_time: $workbook_time
    )
    #  {
    #   idx
    # }
  }
`;

export const WORK_BOOK_DELETE = gql`
  mutation deleteWorkBook($idxs: [Int!]!) {
    deleteWorkBook(idxs: $idxs)
  }
`;

export const WORK_BOOK_ASSIGNED_DELETE = gql`
  mutation deleteWorkBookAssigned($idxs: [Int!]!) {
    deleteWorkBookAssigned(idxs: $idxs)
  }
`;

export const ADD_STUDENT_IN_TESTS = gql`
  mutation addStudentInTests($class_idx: Int!, $students: [Int!]!, $testGroupIdx: Int!, $testGroupAssignedIdx: Int!) {
    addStudentInTests(class_idx: $class_idx, students: $students, testGroupIdx: $testGroupIdx, testGroupAssignedIdx: $testGroupAssignedIdx)
  }
`;
