import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query getUserData {
    getUserData {
      idx
      campus {
        fc_company_idx
        company {
          idx
          is_auto_schedule
        }
      }
      campus_idx
      idx
    }
  }
`;
export const GET_CAMPUS_LIST = gql`
  query getCampusList($company_idx: Int!) {
    getCampusList(company_idx: $company_idx) {
      idx
      name
      code
      type
      phone
      regdate
      region
      member_count
      manager {
        idx
        id
        name
        email
        phone
      }
      campus {
        name
      }
    }
  }
`;

export const GET_META_CAMPUS_LIST = gql`
  query getMetaCampusList($company_name: String!) {
    getMetaCampusList(company_name: $company_name) {
      idx
      name
      code
      type
      phone
      regdate
      region
      member_count
      manager {
        idx
        id
        name
        email
        phone
      }
      campus {
        name
      }
    }
  }
`;

export const GET_CAMPUS_SMALL_LIST = gql`
  query getCampusList($company_idx: Int!) {
    getCampusList(company_idx: $company_idx) {
      idx
      name
    }
  }
`;

export const GET_ALL_CAMPUS_CODE_LIST = gql`
  query getAllCampusCodeList {
    getAllCampusCodeList {
      idx
      name
      code
      company {
        name
      }
    }
  }
`;

//token없이 가져와야 해서...
export const GET_CAMPUS_SMALL_LIST_FOR_TEST = gql`
  query Test($company_idx: Int!) {
    getCampusList(company_idx: $company_idx) {
      idx
      name
    }
  }
`;

export const GET_CAMPUS_INFO = gql`
  query getCampusInfo($campus_idx: Int!) {
    getCampusInfo(campus_idx: $campus_idx) {
      idx
      name
      code
      phone
      postcode
      address
      address2
      manager_name
      manager_phone
      manager_email
      region
      regdate
      etc
      switch_access
      manager {
        idx
        id
        name
        phone
        email
      }
    }
  }
`;

export const GET_CAMPUS_INFO_SHORT = gql`
  query getCampusInfo($campus_idx: Int!) {
    getCampusInfo(campus_idx: $campus_idx) {
      idx
      name
      phone
      address
      fax
      user {
        id
      }
    }
  }
`;

export const GET_CAMPUS_BOOK_INFO_LIST = gql`
  query getCampusBookInfoList($campus_idx: Int!) {
    getCampusBookInfoList(campus_idx: $campus_idx) {
      idx
      book {
        code
        parent_code
        book_sub {
          title
        }
      }
      price
      prices {
        idx
        campus_bd_idx
        book_code
        price
        days
      }
    }
  }
`;

export const CAMPUS_SEARCH_LIST = gql`
  query Test($company_name: String!, $search_type: String!, $search_text: String!) {
    campusSearchList(company_name: $company_name, search_type: $search_type, search_text: $search_text) {
      idx
      name
      address
      address2
      phone
    }
  }
`;

export const GET_CAMPUS_USER_MENU_ITEM_SETTING_LIST = gql`
  query getCampusUserMenuItemSettingList($campus_idx: Int!) {
    campusUserMenuItemSettingList(campus_idx: $campus_idx) {
      idx
      campus_idx
      company_menu_item_id
      is_hidden
    }
  }
`;

export const GET_CAMPUS_OPEN_AI_USAGE_LIST = gql`
  query getCampusOpenAiUsageList($dateStr: String!, $companyId: Int!) {
    getCampusOpenAiUsageList(dateStr: $dateStr, companyId: $companyId) {
      idx
      name
      prompt_token_count
      completion_token_count
      total_token_count
      image_count
      total_cost
    }
  }
`;
