import React, { useMemo } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const TestCenterMenuGroup = ({ currentMenu }) => {
  const history = useHistory();
  const companyName = useSelector(classStoreData);
  const isSmartEclass = useMemo(() => {
    return isAfterSchool(companyName) || companyName === 'educo';
  }, [companyName]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}>
      <MenuButton
        type={`${currentMenu === 'test-management' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/test/manage/list');
        }}
      >
        Test Management
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'test-result' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/test/manage/result');
        }}
      >
        Test Result
      </MenuButton>
      {isSmartEclass && (
        <>
          &nbsp;{' '}
          <MenuButton
            type={`${currentMenu === 'placement-test' ? 'primary' : 'default'} `}
            onClick={() => {
              history.push('/placement-test');
            }}
          >
            Placement Test
          </MenuButton>
        </>
      )}
    </div>
  );
};

export default TestCenterMenuGroup;
