import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Typography, Space, Checkbox, Select, Row, Button, Form, Input, TreeSelect, Modal } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import TestGroupMenuGroup from 'src/components/common/TestGroupMenuGroup';
import { userInfoVar } from 'src/apollo/cache';

import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import { HalfWrapper, MainBlock } from '../common/Styles';
import { openNotification } from 'src/components/common/Notification';
import UseHistoryState from 'src/components/common/UseHistoryState';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const SyllabusMain = () => {
  const history = useHistory();
  const [checkRowList, setcheckRowList] = useState([]);
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      type: userInfoVar()?.type < 5 ? 'publisher' : 'admin',
      text: null,
      page: 1,
      category_ids: [],
    },
    'we_search',
  );

  const { data: fetchSyllabus, refetch } = useQuery(queries.getTests.TEST_GROUP_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      type: searchValue.type,
      searchText: searchValue.text,
      category_ids: searchValue.category_ids,
    },
  });

  const [workBookDelete] = useMutation(mutations.createTests.WORK_BOOK_DELETE, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
  });

  const { data: categoryData } = useQuery(queries.getTests.TEST_CATEGORY_LIST, {
    fetchPolicy: 'no-cache',
    variables:
      searchValue.type !== 'admin'
        ? {
            company_idx: userInfoVar()?.campus?.fc_company_idx,
          }
        : {},
  });

  const listData = useMemo(() => {
    if (fetchSyllabus) {
      return fetchSyllabus.testGroupList.reduce((acc, cur, index) => {
        const obj = {
          key: cur.idx,
          title: cur.name,
          date: moment(cur.idate).utcOffset('+0000').format('YYYY-MM-DD'),
          lesson: cur?.tests?.length,
          No: index + 1,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchSyllabus]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
      width: 50,
    },
    {
      title: 'Book Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: (a, b) => a.title && a.title.localeCompare(b.title),
    },
    {
      title: 'Uploaded Date',
      dataIndex: 'date',
      align: 'center',
      key: 'date',
      width: 120,
    },
    {
      title: 'lesson #',
      dataIndex: 'lesson',
      key: 'lesson',
      width: 90,
      align: 'center',
    },
    {
      title: 'Edit',
      key: '',
      align: 'center',
      width: 100,
      render: (text, record) => {
        const disabled =
          (searchValue.type === 'admin' && userInfoVar()?.type !== 5) || (userInfoVar()?.type <= 3 && searchValue.type === 'publisher');
        return (
          <Space size="middle">
            {disabled ? (
              <Button disabled={true} style={{ color: '#1890ff' }}>
                Edit
              </Button>
            ) : (
              <Link to={`/test/syllabus/edit/${record.key}`}>
                <Button style={{ color: '#1890ff' }}>Edit</Button>
              </Link>
            )}
          </Space>
        );
      },
    },
    {
      title: 'List 보기',
      key: '',
      align: 'center',
      render: (text, record) => {
        const disabled = userInfoVar()?.type > 2;
        return (
          <Space size="middle">
            {disabled ? (
              <Button disabled={true} style={{ color: '#1890ff' }}>
                Assign
              </Button>
            ) : (
              <Link to={`/test/syllabus/${record.key}/assign`}>
                <Button style={{ color: '#1890ff' }}>Assign</Button>
              </Link>
            )}
          </Space>
        );
      },
      width: 100,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
    getCheckboxProps: (record) => ({
      disabled:
        (searchValue.type === 'admin' && userInfoVar()?.type !== 5) || (userInfoVar()?.type <= 3 && searchValue.type === 'publisher'),
    }),
  };
  console.log('checkRowList', checkRowList);
  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          workBookDelete({ variables: { idxs: checkRowList } });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current,
      };
    });
  }

  return (
    <>
      {userInfoVar()?.type === 5 || userInfoVar()?.type === 4 ? (
        <div style={{ padding: '10px 20px 0', margin: 0 }}>
          <Title level={3} style={{ margin: 0 }}>
            Syllabus Management
          </Title>
        </div>
      ) : (
        <TestGroupMenuGroup currentMenu="test-management" />
      )}
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="syllabus-mailblock">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={6} xs={4} md={6} style={{ textAlign: 'left' }}>
              <Title level={4}>Syllabus Main Page</Title>
            </Col>
            <Col
              className="contenttest-headitems"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Form.Item label="Folder" className="headitems-select">
                <Select
                  style={{ width: 150, color: '#065aa9' }}
                  defaultValue={searchValue.type}
                  placeholder="Select a folder"
                  optionFilterProp="children"
                  onChange={(val) =>
                    setSearchValue((prev) => {
                      return {
                        ...prev,
                        type: val,
                      };
                    })
                  }
                >
                  <Select.Option value="admin">Cloubot</Select.Option>
                  {userInfoVar()?.type < 5 && (
                    <>
                      <Select.Option value="">전체</Select.Option>

                      <Select.Option value="publisher">FC 본사</Select.Option>
                    </>
                  )}
                  {userInfoVar()?.type < 4 && (
                    <>
                      <Select.Option value="campus">우리 학원 컨텐츠</Select.Option>
                      <Select.Option value="me">내 개인 컨텐츠</Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>
              <TreeSelect
                style={{ width: 200, color: '#065aa9', marginLeft: '10px' }}
                defaultValue={searchValue.category_ids}
                placeholder="Select a category"
                optionFilterProp="children"
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                onChange={(value) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      category_ids: value,
                    };
                  })
                }
                treeData={categoryData?.testCategoryList.map((f) => {
                  return {
                    title: f.name,
                    value: f.idx,
                    key: f.idx,
                    children: f.other_test_category?.map((s) => {
                      return {
                        title: s.name,
                        value: s.idx,
                        key: s.idx,
                        children: s.other_test_category?.map((t) => {
                          return {
                            title: t.name,
                            value: t.idx,
                            key: t.idx,
                          };
                        }),
                      };
                    }),
                  };
                })}
              />
              <Search
                className="headitems-search"
                placeholder="Input search text"
                defaultValue={searchValue.text}
                onSearch={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      text: val,
                    };
                  })
                }
                allowClear
                style={{ width: 250, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, height: 32 }}
              />
              <Link to="/test/syllabus/add" className="headitems-add">
                <Button style={{ color: '#289428' }}>Add +</Button>
              </Link>

              <CustomButton className="testmanagmentdelete-btn" danger onClick={deleteConfirm}>
                Delete
              </CustomButton>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            dataSource={listData}
            columns={columns}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={{ pageSize: 10, current: searchValue.page }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => onHandleRowClick(record, rowIndex),
            //   };
            // }}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default SyllabusMain;
