import React, { useMemo } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const TestGroupMenuGroup = ({ currentMenu }) => {
  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}>
      <MenuButton
        type={`${currentMenu === 'test-management' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/test/syllabus/main');
        }}
      >
        Syllabus Main Page
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'test-result' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/test/syllabus/assigned');
        }}
      >
        Assigned Syllabus
      </MenuButton>
    </div>
  );
};

export default TestGroupMenuGroup;
