import React, { useState, useMemo } from 'react';
import { Col, Row, Radio, Button, Modal, Image } from 'antd';
import LevelTab, { useLevelTab } from './LevelTab';
import { BookCard, BookCover, BookMeta, BookTitle, BOOK_COL_STYLE, THUMBNAIL_BASE_URL,SMART_ECLASS_LINK_BUTTON_STYLE, } from './CommonComp';

// import { useSelector } from 'react-redux';
// import { classStoreData } from 'src/operations/store';

const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;
// const GROUPS = ['SB', 'WB', 'Lime Tree Readers'];
const LEVELS = [1, 2, 3, 4, 5, 6];
const VOLUMES = [1, 2, 3, 4, 5, 6];
const GROUPS = ['SB', 'WB'];
//스마트이클래스, 스니커즈 공통으로 사용함..어쩔 수..
const plusUrl = [
  ['xfcb', 'lxgt', 'wycn', 'hwsk', 'obet', 'tphy'],
  ['kyrc', 'hbag', 'tnac', 'jndw', 'dmvl', 'ypor'],
  ['irsb', 'zapo', 'wqbo', 'crzm', 'ewqh', 'ojux'],
  ['hdso', 'qhug', 'gwfo', 'gdtj', 'wnay', 'mkcf'],
  ['nrea', 'njht', 'jwxm', 'akxg', 'cunt', 'edyw'],
  ['hilj', 'jhqr', 'qmth', 'skjn', 'lims', 'empz'],
];
const plusUrlWB = [
  ['osyk', 'nwsg', 'spij', 'nuyx', 'cdal', 'psum'],
  ['gcsw', 'hwlr', 'xmec', 'jqez', 'fqur', 'gebc'],
  ['udic', 'gbnm', 'wovd', 'atfb', 'qzvu', 'firm'],
  ['xlyj', 'uyzo', 'afnb', 'zpij', 'cisv', 'lepc'],
  ['thuk', 'pnvy', 'cvso', 'engh', 'nvtf', 'ikhr'],
  ['mlds', 'pmzb', 'oqcz', 'wqdt', 'smvh', 'tadp'],
];
const IMAGE_NAMES = {
  title: 'Lime Tree',
  sb: 'limetree_',
  wb:'LTR_WB_',
  link_name: 'https://online.flipbuilder.com/sangbong91/',
};
const LimeTreeReaders = ({checkForEduco=false}) => {
  // const companyName = useSelector(classStoreData);
  const [groupNo, setGroupNo] = useState(0);
  const { levelNo, onChangeLevelNo } = useLevelTab();
  const handleChangeGroup = (evt) => {
    const {
      target: { value },
    } = evt;
    setGroupNo(value);
    // onChangeLevelNo(0);
  };
  return (
    <>
      <div style={{ height: '72vh', overflowY: 'scroll', overflowX: 'hidden' }}>
        <LevelTab
          levelNo={levelNo}
          levels={LEVELS}
          onChage={(evt) => {
            const {
              target: { value },
            } = evt;
            onChangeLevelNo(value);
          }}
        />
        <Radio.Group
          value={groupNo}
          style={{ marginBottom: 16, marginLeft: 16 }}
          buttonStyle="solid"
          onChange={handleChangeGroup}
          size="middle"
        >
          {GROUPS.map((groupName, key) => (
            <Radio.Button key={`radio-level-key${key}`} value={key} style={{ width: 160, textAlign: 'center' }}>
              {groupName}
            </Radio.Button>
          ))}
        </Radio.Group>
        {LEVELS.filter((level) => (levelNo === 0 ? true : level === levelNo)).map((lv) => (
          <Row key={`row-${lv}`} gutter={[16, 16]} style={{ margin: '0 10px' }}>
            {VOLUMES.map((vol) => (
              <Col
                span={4}
                key={`col-${lv}-${vol}`}
                style={{
                  ...BOOK_COL_STYLE,
                  borderRight: `${vol === 6 ? '1px solid #dedede' : ''}`,
                }}
              >
                <BookTitle level={5} style={{ textAlign: 'center' }}>
                  {`${IMAGE_NAMES.title} ${lv}-${vol} ${groupNo===1?'WB':''}`}
                </BookTitle>
                <BookCard
                  onClick={() => {
                    window.open(
                      `${IMAGE_NAMES.link_name}${groupNo ===0?plusUrl[lv - 1][vol - 1]:plusUrlWB[lv - 1][vol - 1]}`,
                      '_blank',
                      'height=' + window.screen.height + ',width=' + window.screen.width + 'fullscreen=yes',
                    );
                  }}
                  cover={
                    <BookCover
                      style={{
                        backgroundImage: `url(${THUMBNAIL_BASE_URL}${groupNo===0?IMAGE_NAMES.sb:IMAGE_NAMES.wb}${lv}-${vol}.${groupNo===0?'jpg':'png'})`,
                        border: '0px solid #fff',
                      }}
                    />
                  }
                >
                  {groupNo === 0 &&!checkForEduco&& <BookMeta description={<EbookLinkGroup level={lv} volume={vol} />} />}
                </BookCard>
              </Col>
            ))}
          </Row>
        ))}
      </div>
    </>
  );
};

export default LimeTreeReaders;
const EbookLinkGroup = ({ volume,level }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, paddingBottom: 5 }}>
      <Button
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.8em' }}
        onClick={(e) => {
          e.stopPropagation();
          const culp_token = window.localStorage.getItem('culp_token');
          window.open(`${STUDY_TOOL_HOST}/LTR-L${level}V${volume}U1D1?token=${culp_token}`, '_blank');
        }}
      >
        E-learning
      </Button>
      <Button
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.8em' }}
        onClick={() => {
          window.open(`${IMAGE_NAMES.link_name}${level>2?'fcyw':'xfeo'}`, '_blank', 'width=1400, height=900');
        }}
      >
        TG
      </Button>
    </div>
  );
};